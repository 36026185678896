import React from 'react';

import {
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Row,
	Space,
	Typography,
} from 'antd';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import client from '@/lib/http/client';

const ResetPassword = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = React.useState(false);

	const [confirm, setConfirm] = React.useState(false);
	const [stage, setStage] = React.useState(1);

	const onEmailCheck = () => {
		setLoading(true);
		client
			.post('users/request-reset-password', {
				email: form.getFieldValue('email'),
			})
			.then((res) => {
				// console.log(res);
				setStage(2);
			})
			.catch((err: AxiosError<any, any>) => {
				console.log(err);
				form.setFields([
					{ name: 'email', errors: [err.response?.data.message] },
				]);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onPasswordChange = () => {
		setLoading(true);
		console.log({
			email: form.getFieldValue('email'),
			token: form.getFieldValue('token'),
			password: form.getFieldValue('password'),
		});

		client
			.post('users/confirm-reset-password', {
				email: form.getFieldValue('email'),
				token: form.getFieldValue('token'),
				password: form.getFieldValue('password'),
			})
			.then((res) => {
				navigate('/auth/login');
			})
			.catch((err: AxiosError<any, any>) => {
				form.setFields(
					Array.isArray(err.response?.data.message)
						? err.response?.data.message.map((v: string) => {
								return {
									name: v.split(' ')[0],
									errors: [v],
								};
						  })
						: [
								{
									name: err.response?.data.message.split(' ')[0],
									errors: [err.response?.data.message],
								},
						  ],
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const navigate = useNavigate();
	return (
		<Card title="Reset Password" style={{ minWidth: '428px' }}>
			<Form
				layout="vertical"
				form={form}
				disabled={loading}
				// onFinish={(values) => {}}
			>
				<Form.Item
					label="Type your email"
					name="email"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input disabled={stage === 2} />
				</Form.Item>

				{stage > 1 && (
					<Form.Item
						label="Verification code from email"
						name="token"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input placeholder="" />
					</Form.Item>
				)}

				{stage > 1 && (
					<Form.Item
						label="New password"
						name="password"
						rules={[
							{
								required: true,
							},
						]}
						help=<div>
						<p>
							Password must be at least 8 characters long and contain at least:
						</p>
						<ul>
							<li>one uppercase letter</li>
							<li>one lowercase letter</li>
							<li>one number</li>
							<li>one special character</li>
						</ul>
					</div>
					>
						<Input.Password placeholder="" />
					</Form.Item>
				)}

				{stage === 1 && (
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							block
							loading={loading}
							onClick={onEmailCheck}
						>
							Check
						</Button>
					</Form.Item>
				)}

				{stage === 2 && (
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							block
							loading={loading}
							onClick={onPasswordChange}
						>
							Change password
						</Button>
					</Form.Item>
				)}
			</Form>
		</Card>
	);
};

export default ResetPassword;
