import React, { useContext } from 'react';

import { Button } from 'antd';
import { omit, uniqueId } from 'lodash';
import { v4 as genId } from 'uuid';

import { SubscriptionContext } from '@/App';
import { ClientSubscription } from '@/layouts/subscription/subscriptionType';

import style from './style.module.css';

import InfluencerCard from './card';

export interface IInfluencers {
	[key: string]: any;
}
interface IProps {
	onGenerateReport: (payload: any) => void;
	// totalReports: number;
	// changeTotalReports: (count: number) => void;
}

const SecondStep = (props: IProps) => {
	const [influencers, setInfluencers] = React.useState({
		[genId()]: { scrapeTo: '31', errors: {}, tags: [] },
	} as IInfluencers);

	const { repotsCount, setRepotsCount } = useContext(SubscriptionContext);
	return (
		<>
			{Object.keys(influencers || {}).length > 0 &&
				Object.entries(influencers || {}).map((v) => {
					return (
						<InfluencerCard
							key={v[0]}
							rowKey={v[0]}
							influencer={v[1]}
							setInfluencers={setInfluencers}
							influencersLength={Object.keys(influencers).length}
							onDelete={(x) => {
								setInfluencers(omit(influencers, [x]));

								setRepotsCount(repotsCount + 1);
							}}
						/>
					);
				})}

			<div className={style.controls}>
				<Button
					disabled={
						Object.values(influencers)
							.map((x) => !!x.platformHandles)
							.includes(false) || repotsCount < 1
					}
					onClick={() => {
						setInfluencers((x: any) => ({
							...x,
							[genId()]: { scrapeTo: '31', errors: {} },
						}));
						setRepotsCount(repotsCount - 1);
					}}
				>
					Add Influencer
				</Button>

				<Button
					type="primary"
					/*disabled={
						Object.values(influencers)
							.map((v) => (v._id ? true : false))
							.includes(false) ||
						Object.values(influencers)
							.map((x) =>
								Object.values(x.errors)
									.map((y: any) => y.length !== 0)
									.includes(true),
							)
							.includes(true)
					}*/
					style={{ display: 'block' }}
					onClick={() =>
						props.onGenerateReport(
							Object.values(influencers).reduce((acc, i) => {
								return {
									...acc,
									[i.profileName]: {
										_id: i._id,
										report_id: genId(),
										platforms: Object.values(i.platforms).map(
											(x: any) => x.url,
										),
										name: i.profileName,
										image: i.profileImageURL,
										reportType: 1,
										scrapeTo: i.scrapeTo || '0',
										searchTags: i.tags, // Include the selected tags in the payload
									},
								};
							}, {}),
						)
					}
				>
					Generate Report(s)
				</Button>
			</div>
		</>
	);
};

export default SecondStep;

// function omit(keys: any, obj: any): any {
// 	if (!keys.length) return obj;
// 	const { [keys.pop()]: omitted, ...rest } = obj;
// 	return omit(keys, rest);
// }
