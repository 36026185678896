import { useContext } from 'react';
import React from 'react';

import {
	createBrowserRouter as newRouter, // createHashRouter as newRouter,
	redirect,
} from 'react-router-dom';
import { Navigate, useRoutes } from 'react-router-dom';

import App from './App';
import { UserContext } from './App';
import DashboardLayout from './layouts/dashboard';
import OnBoardingLayout from './layouts/onboarding';
import SubscriptionLayout from './layouts/subscription';
import SubscriptionPlans from './layouts/subscription/SubscriptionForms';
import Dashboard from './pages/app/dashboard';
import ComingSoon from './pages/app/dashboard/coming-soon';
import Creators from './pages/app/dashboard/creators';
import CreatorReport2 from './pages/app/dashboard/creators/creator/report-new';
import ProductMainMenuPage from './pages/app/dashboard/product-main-menu';
import Reports from './pages/app/dashboard/reports';
import UnauthorizedView from './pages/app/dashboard/unauthorized';
import VideoDataLake from './pages/app/dashboard/video-data-lake';
import VideoInsightsCoPilot from './pages/app/dashboard/video-insights-co-pilot';
import VideoIntelligenceOnDemand from './pages/app/dashboard/video-intelligence-on-demand';
import { ActivityHistory } from './pages/app/settings/activityhistory';
import Billing from './pages/app/settings/billing/view';
import OrganizationInfo from './pages/app/settings/organisation/view';
import ProfileSettings from './pages/app/settings/profile-settings/view';
import UsersView from './pages/app/settings/users/view';
import Subscribe from './pages/app/subscribe';
import SubscribeTopUp from './pages/app/subscribe-top-up';
import { Completion } from './pages/app/subscribe/Completion';
import ConfirmRegister from './pages/auth/confirm';
import JoinTeam from './pages/auth/join';
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import ResetPassword from './pages/auth/reset-password';
import OnBoarding from './pages/onboarding';

// Import your UserContext
const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { isAdmin } = useContext(UserContext);

	return isAdmin ? <>{children}</> : <UnauthorizedView />;
};

export const router = newRouter([
	// ENTRYPOINT REDIRECT
	{
		index: true,
		loader: async () => {
			throw redirect('/app/dashboard/product-main-menu');
		},
	},

	// AUTH
	{
		path: '/auth',
		element: <OnBoardingLayout />,
		children: [
			{ path: 'login', element: <Login /> },
			{ path: 'register', element: <Register /> },
			{ path: 'confirm', element: <ConfirmRegister /> },
			{ path: 'join', element: <JoinTeam /> },
			{ path: 'reset-password', element: <ResetPassword /> },
		],
	},

	// APP
	{
		path: '/app',
		element: <App />,
		children: [
			// ONBOARDING
			{
				element: <OnBoardingLayout />,
				path: 'setup',
				children: [
					{
						index: true,
						element: <OnBoarding />,
					},
				],
			},
			{
				element: <DashboardLayout />,

				children: [
					{
						index: true,
						element: <Subscribe />,
					},
					{
						path: 'completion',
						element: <Completion />,
					},
				],
				path: 'subscribe',
			},

			{
				element: <DashboardLayout />,

				children: [
					{
						index: true,
						element: <SubscribeTopUp />,
					},
				],
				path: 'subscribe-top-up',
			},
			// // SUBSCRIPTIONS
			// {
			// 	element: <SubscriptionLayout />,
			// 	path: 'subscriptions',
			// },

			// DASHBOARD
			{
				element: <DashboardLayout />,
				// index: true,
				path: 'dashboard',
				children: [
					{
						index: true,

						loader: async () => {
							throw redirect('/app/dashboard/product-main-menu');

							// throw redirect('/app/dashboard/creators');
						},
					},
					{
						path: 'product-main-menu',
						element: <ProductMainMenuPage />,
					},
					{
						path: 'video-data-lake',
						element: <VideoDataLake />,
					},
					{
						path: 'video-intelligence-on-demand',
						element: <VideoIntelligenceOnDemand />,
					},
					{
						path: 'video-insights-co-pilot',
						element: <VideoInsightsCoPilot />,
					},
					{
						path: 'creators',
						element: <Creators />,
					},
					// {
					// 	path: 'reports',
					// 	element: <Reports />,
					// },
					// {
					// 	path: 'creators/:id/report',
					// 	element: <CreatorReport />,
					// },
					{
						path: 'creators/:id/report-v2',
						element: <CreatorReport2 />,
					},
					{
						path: 'users',
						element: (
							<ProtectedRoute>
								<UsersView />
							</ProtectedRoute>
						),
					},
					{
						path: 'files',
						element: <ComingSoon />,
					},
					// TODO: uncomment
					{
						path: 'activity',
						element: <ComingSoon />, // TODO: Wrap in <ProtectedRoute> when ready
						// element: <ActivityHistory />,
					},
					{
						path: 'organisation',
						element: (
							<ProtectedRoute>
								<OrganizationInfo />
							</ProtectedRoute>
						),
					},
					{
						path: 'subscription',
						element: (
							<ProtectedRoute>
								<SubscriptionLayout />
							</ProtectedRoute>
						),
					},
					{
						path: 'subscription-plans',
						element: (
							<ProtectedRoute>
								<SubscriptionPlans />
							</ProtectedRoute>
						),
					},
					{
						path: 'billing',
						element: <ComingSoon />, // TODO: Wrap in <ProtectedRoute> when ready
					},
					{
						path: 'profile-settings',
						element: <ProfileSettings />,
					},
				],
			},
		],
	},
]);
