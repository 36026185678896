import { useContext, useEffect, useState } from 'react';

import {
	BookOutlined,
	DownloadOutlined,
	ReloadOutlined,
} from '@ant-design/icons';
import {
	Alert,
	Button,
	Flex,
	Space,
	Spin,
	Table,
	Tooltip,
	message,
} from 'antd';

import { SubscriptionContext } from '@/App';
import {
	downloadBatchJsonData,
	useGetAllCustomerProcesses,
} from '@/api/batch_process';
import { ClientExtendedSubscription } from '@/layouts/subscription/subscriptionType';
import { useSubscription } from '@/layouts/subscription/useSubscription';
import downloadZipFile from '@/lib/helpers/downloadZipFile';
import { PageContext } from '@/providers/PageContextProvider';

import { columns, mockData } from './data';

export interface IPagination {
	page: number;
	pageCount: number;
	total: number | undefined;
	limit: number;
}

export default function VideoIntelligenceOnDemand() {
	const { setPageTitle, openDrawer, setIsCreditsCounterVisible } =
		useContext(PageContext);
	const { subscriptions } = useContext(SubscriptionContext);
	const { isLoading: isSubscriptionLoading } = useSubscription();
	const [jsonDataLoading, setJsonDataLoading] = useState({
		isLoading: false,
		id: '',
	});
	const [isRefreshEnabled, setIsRefreshEnabled] = useState(false);
	const [pagination, setPagination] = useState<IPagination>({
		page: 1,
		pageCount: 1,
		total: 0,
		limit: 10,
	});

	const { data, isLoading, isFetching, refetch } = useGetAllCustomerProcesses(
		{
			page: pagination.page,
			limit: pagination.limit,
		},
		isRefreshEnabled,
	);

	const total = data?.data?.data?.length ?? 1;
	const batchItems = data?.data?.data ?? [];

	useEffect(() => {
		if (batchItems?.some((item: any) => item?.status === 'processing')) {
			setIsRefreshEnabled(true);
		}
	}, [batchItems]);

	useEffect(() => {
		setPageTitle('Video intelligence on-demand');
		setIsCreditsCounterVisible(false);

		return () => {
			setPageTitle('');
			setIsCreditsCounterVisible(true);
		};
	}, []);

	const onTableChange = (__: any, filters: any, sorter: any) => {
		const { field = 'created_at', order = 'descend' } = sorter;
		const platform = filters.platforms?.[0] ?? '';
		const curStatus = filters.status?.[0] ?? '';
		const type = filters.type?.[0] ?? '';

		// setSearchParams((searchParams) => {
		//   searchParams.set("status", curStatus || "");
		//   searchParams.set("type", type || "");
		//   searchParams.set("filters", platform || "");

		//   field && searchParams.set("sortBy", field);
		//   order &&
		// 	searchParams.set("sortOrder", order === "ascend" ? "asc" : "desc");

		//   return searchParams;
		// });
	};

	const onDownLoadDataClick = async (record: any) => {
		try {
			setJsonDataLoading({
				isLoading: true,
				id: record?.batch_id,
			});

			const response = await downloadBatchJsonData(record?.batch_id);

			console.log('response', response);

			if (response.status) {
				downloadZipFile(record?.batch_id, response?.data);
			}
		} catch (error) {
			message.error(`Something went wrong: ${error}`);
		} finally {
			setJsonDataLoading({ isLoading: false, id: '' });
		}
	};

	const hasActiveApiKey = subscriptions?.some(
		(subscription: ClientExtendedSubscription) =>
			subscription?.api_key && subscription?.active,
	);

	return (
		<>
			{isSubscriptionLoading ? (
				<Flex
					align="center"
					justify="center"
					style={{ width: '100%', paddingTop: '5rem' }}
				>
					<Spin size="large" />
				</Flex>
			) : (
				<>
					{hasActiveApiKey ? (
						<div>
							<Flex
								align="center"
								justify="space-between"
								style={{ marginBottom: '1.5rem' }}
							>
								<h2>Video on demand - batch process history</h2>

								<Space>
									<Tooltip title="Refresh">
										<Button
											onClick={() => refetch()}
											icon={<ReloadOutlined />}
										/>
									</Tooltip>

									<Button
										type="primary"
										onClick={() => openDrawer('add-new-batch-process')}
									>
										New
									</Button>
								</Space>
							</Flex>

							<Table
								loading={isLoading || isFetching}
								style={{ width: '100%' }}
								// onChange={onTableChange}
								dataSource={batchItems}
								// dataSource={mockData}
								columns={[
									...columns,
									{
										title: 'Actions',
										dataIndex: 'actions',
										key: 'actions',
										width: 60,
										flex: true,
										render: (_: any, record: any) => {
											return (
												<Space>
													<Tooltip title="View details">
														<Button
															onClick={() =>
																openDrawer('view-batch-process-details', record)
															}
															disabled={record.status !== 'ready'}
															icon={<BookOutlined />}
														/>
													</Tooltip>
													<Tooltip title="Download json">
														<Button
															onClick={() => onDownLoadDataClick(record)}
															disabled={
																record.status !== 'ready' ||
																(jsonDataLoading.id === record?.batch_id &&
																	jsonDataLoading.isLoading)
															}
															loading={
																jsonDataLoading.id === record?.batch_id &&
																jsonDataLoading.isLoading
															}
															icon={<DownloadOutlined />}
														/>
													</Tooltip>
												</Space>
											);
										},
									},
								]}
								rowKey={'batch_id'}
								bordered
								scroll={{ x: 1100 }}
								pagination={{
									total,
									current: pagination.page,
									showSizeChanger: true,
									pageSizeOptions: [10, 25, 50],
									onChange: (page: number, pageSize: number) => {
										console.log('pageSize', pageSize);

										setPagination((cur) => ({ ...cur, page, limit: pageSize }));
									},
								}}
							/>
						</div>
					) : (
						<div>
							<Flex
								vertical
								align="center"
								justify="space-between"
								style={{ marginBottom: '1.5rem' }}
							>
								<Alert
									type="error"
									message="API key required"
									description="You don't have API key to access this page. Please check your subscription."
								/>
							</Flex>
						</div>
					)}
				</>
			)}
		</>
	);
}
