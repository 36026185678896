import React, { useContext, useEffect, useState } from 'react';

import {
	BookOutlined,
	CheckCircleOutlined,
	CheckCircleTwoTone,
	CloseCircleOutlined,
	EditOutlined,
	RedoOutlined,
	SearchOutlined,
	StopOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
	Avatar,
	Badge,
	Button,
	Drawer,
	Flex,
	Form,
	Input,
	Modal,
	Select,
	Space,
	Spin,
	Switch,
	Table,
	TableProps,
	Tag,
	Tooltip,
	Typography,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Link, useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { SubscriptionContext, UserContext } from '@/App';
import { useDebounce } from '@/hooks';
import { ReportStatus, ReportStatusData } from '@/interfaces/reportStatus';
import { ReactComponent as UpdateSubscription } from '@/layouts/subscription/src/UpdateSubscription.svg';
import client from '@/lib/http/client';
import { PageContext } from '@/providers/PageContextProvider';

import style from './style.module.css';

import Creator from './creator';
import {
	getSortQueryParams,
	reGenReport,
	setBadgeColor,
	setBadgeText,
} from './helper';
import Platforms from './platforms';
import AddReport from './report';

// ...

dayjs.extend(relativeTime);
const Creators = () => {
	let [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState(searchParams.get('q') || '');
	const debouncedSearch = useDebounce(search, 500);
	const { repotsCount, setRepotsCount, subscribe, refetch } =
		useContext(SubscriptionContext);
	const { setPageTitle } = useContext(PageContext);

	const profile = useContext(UserContext);

	const [isAdmin, setIsAdmin] = useState(profile?.user?.check?.[2] ?? false);

	const [showDebug, setShowDebug] = useState(true);

	const [loading, setLoading] = useState(false);

	const [tagsReportId, setTagsReportId] = useState<string>('');
	const [addReport, setAddReport] = useState(false);
	const [modalTags, setModalTags] = useState<string[]>([]);
	const [open, setOpen] = useState(false);
	const [creator, setCreator] = useState<any>('');
	const [sort, setSort] = useState(searchParams.get('srt') || '');
	const [options, setOptions] = useState<string[]>(
		searchParams.get('tags')?.split(',') || [],
	);

	useEffect(() => {
		setPageTitle('Influencer Integrity report');

		return () => setPageTitle('');
	}, []);

	const handleTagChange = (value: string[]) => {
		setOptions(value);
		console.log(value);
	};

	const onClose = () => {
		setOpen(false);
		setCreator('');
	};

	const tagRender = (props: {
		label: any;
		value: any;
		closable: any;
		onClose: any;
	}) => {
		const { label, value, closable, onClose } = props;
		return (
			<Tag
				color="blue"
				closable={closable}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{value}
			</Tag>
		);
	};

	const onTagChange = (event: string[]) => {
		setModalTags(event);
	};

	const [openModal, setOpenModal] = React.useState(false);
	const [openModalCredits, setOpenModalCredits] = React.useState(
		!isAdmin && (!repotsCount || !subscribe),
	);

	const showModal = () => {
		setOpenModal(true);
	};

	const handleCancel = () => {
		setOpenModal(false);
		setModalTags([]);
	};

	const handleModalCredits = () => {
		setOpenModalCredits(false);
	};

	const handleOk = async () => {
		try {
			setLoading(true);
			await client.post(`/reports/updatetags`, {
				id: tagsReportId,
				tags: modalTags,
			});
			reports.refetch();
			setOpenModal(false);
		} catch (err) {
			const error = err as AxiosError;
			const data: any = error.response?.data;
			if (data) {
				form.setFields([{ name: 'tags', errors: [data.message] }]);
			}
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const [form] = Form.useForm();

	const sortValues = (arrayofPaths: string[]) => {
		const groupedPaths: string[][] = [];

		// Group URLs in sets of three
		for (let i = 0; i < arrayofPaths.length; i += 3) {
			const group = arrayofPaths.slice(i, i + 3);
			groupedPaths.push(firstStepSort(group));
		}
		return groupedPaths.flat();
	};

	const firstStepSort = (arrayOfPaths: string[]): string[] => {
		const wordOrder: string[] = ['youtube', 'instagram', 'tiktok'];

		return arrayOfPaths.sort((a, b) => {
			const lowerA = a?.toLowerCase();
			const lowerB = b?.toLowerCase();

			for (let i = 0; i < wordOrder.length; i++) {
				const word = wordOrder[i];
				const includesWordA = lowerA?.includes(word);
				const includesWordB = lowerB?.includes(word);

				if (includesWordA && includesWordB) {
					return lowerA?.indexOf(word) - lowerB?.indexOf(word);
				} else if (includesWordA) {
					return -1;
				} else if (includesWordB) {
					return 1;
				}
			}

			return 0; // If none of the keywords are found
		});
	};

	function setQueryPage(pageNumber: number) {
		searchParams.set('p', pageNumber.toString());
		setSearchParams(searchParams);
	}

	const location = useLocation();
	// Extract the query parameters from the location object
	const queryParams = new URLSearchParams(location.search);
	const pageNumber = queryParams.get('p') || '1';

	const reports = useQuery({
		queryKey: [
			'reports',
			pageNumber,
			sort,
			debouncedSearch,
			searchParams.get('tags'),
		],
		queryFn: () =>
			client.get<ReportStatus>(
				`/reports?q=${debouncedSearch}&p=${pageNumber}&srt=${sort}&tags=${
					searchParams.get('tags') || ''
				}`,
			),
		// enabled: search.length > 0,
		refetchInterval: 15000,
	});

	const onGenerateReport = (payload: any) => {
		setLoading(true);
		client
			.post(`/reports/generate`, payload)
			.then(() => reports.refetch())
			.then(() => {
				refetch();
				setLoading(false);
			});
	};

	const onStopReport = (report_id: string) => {
		setLoading(true);
		client
			.post(`/reports/stop`, { report_id })
			.then(() => {
				return reports.refetch();
			})
			.then(() => {
				refetch();
				setLoading(false);
			});
	};

	const onChange: TableProps<any>['onChange'] = (
		pagination,
		filters,
		sorter,
		extra,
	) => {
		const sorting = (Array.isArray(sorter) ? sorter : [sorter])
			.filter((x) => !!x.order)
			.map((v) => `${v.columnKey}:${v.order === 'ascend' ? 'asc' : 'desc'}`)
			.join(',');

		if (!sorting) {
			searchParams.delete('srt');
			setSearchParams(searchParams);
			setSort('');
			return;
		}

		searchParams.set('srt', sorting);
		setSearchParams(searchParams);
		setSort(sorting);
	};

	return (
		<div
			className={style.root}
			style={{
				position: 'relative',
				overflow: 'hidden',
				minHeight: 'calc(100vh - 105px)',
			}}
		>
			<div className={style.searchBar}>
				<div>
					<Title ellipsis className={style.customerName} level={4}>
						{profile.user.cName || ''}
					</Title>
				</div>
				<div className={style.row}>
					{profile.user.dView && (
						<div
							style={{
								marginRight: '10px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Switch
								checkedChildren=""
								unCheckedChildren=""
								checked={showDebug}
								onChange={() => setShowDebug(!showDebug)}
							></Switch>
						</div>
					)}
					<div>
						<Input
							allowClear
							defaultValue={searchParams.get('q') || ''}
							placeholder="Search creator"
							prefix={<SearchOutlined />}
							style={{ minWidth: '318px' }}
							onChange={(e) => {
								if (e.target.value === '') {
									searchParams.delete('q');
									searchParams.set('p', '1');
									setSearch(e.target.value);
									setSearchParams(searchParams);
									return;
								}
								setSearch(e.target.value);
								searchParams.set('q', e.target.value);
								searchParams.set('p', '1');
								setSearchParams(searchParams);
							}}
						/>
					</div>
					<div>
						<Button
							onClick={() => {
								setAddReport(true);
								repotsCount && setRepotsCount(repotsCount - 1);
							}}
							type="primary"
							disabled={!isAdmin && (!repotsCount || !subscribe)}
						>
							New Report
						</Button>
					</div>
				</div>
			</div>

			<Spin spinning={reports.isLoading || loading}>
				<Table
					dataSource={(reports.isFetched && reports?.data?.data?.data) || []}
					loading={reports.isLoading}
					bordered
					onChange={onChange}
					scroll={{ x: 1300 }}
					pagination={{
						size: 'default',
						total: reports?.data?.data.meta.total,
						pageSize: 10,
						current: +pageNumber,
						onChange(page, pageSize) {
							setQueryPage(page);
						},
						showLessItems: true,
						hideOnSinglePage: true,
						responsive: true,
						showSizeChanger: false,
						position: ['topRight', 'bottomRight'],
					}}
					rowKey={'report_id'}
				>
					<Table.Column
						title={
							reports.isFetching ? (
								<SyncOutlined spin style={{ color: '#1677ff' }} />
							) : (
								<CheckCircleTwoTone />
							)
						}
						key="Avatar"
						width={110}
						align="center"
						render={(value: ReportStatusData, _, idx) => {
							const sortedPaths = sortValues(value.creator_img);

							const slicedSortedPaths =
								sortedPaths?.length > 3 ? sortedPaths.slice(0, 3) : sortedPaths;

							return (
								<>
									<Space
										key={idx}
										direction="horizontal"
										style={{ cursor: 'pointer' }}
										size={15}
										// onClick={() => onOpen(value.creator_id)}
									>
										<Avatar.Group>
											{slicedSortedPaths.map((image, idx) => {
												return (
													<Avatar
														key={image + idx}
														src={
															image?.startsWith('http')
																? image
																: process!.env['REACT_APP_CDN_STORAGE']! + image
														}
													></Avatar>
												);
											})}
										</Avatar.Group>
									</Space>
									{sortedPaths?.length > 3 && (
										<Typography.Text>
											+ {sortedPaths?.length - 3} more
										</Typography.Text>
									)}
								</>
							);
						}}
					/>
					<Table.Column
						title="Creator"
						key="title"
						defaultSortOrder={getSortQueryParams(
							'title',
							searchParams.get('srt'),
						)}
						sorter={{ multiple: 1 }}
						render={(value: ReportStatusData, _, idx) => {
							const sortedUrls = sortValues(value.creator_url);

							const creatorTitle =
								value.title[value.creator_url.indexOf(sortedUrls[0])];

							return (
								<Space
									direction="horizontal"
									style={{ cursor: 'pointer' }}
									size={15}
									key={idx}
								>
									<span>
										{creatorTitle?.length ? creatorTitle : <code>null</code>}
									</span>
								</Space>
							);
						}}
						ellipsis
					/>

					<Table.Column
						key={'platforms'}
						title="Platforms"
						align="center"
						width={150}
						render={(value: ReportStatusData, _, i) => {
							const sortedUrls = sortValues(value.creator_url);
							const slicedSortedUrls =
								sortedUrls?.length > 3 ? sortedUrls.slice(0, 3) : sortedUrls;

							return (
								<Flex key={i} vertical justify="center">
									<Flex gap={'10px'} align="center" justify="center">
										{slicedSortedUrls.map((url, idx) => {
											let index = value.creator_url.indexOf(url);

											return (
												<Tooltip
													key={url + idx}
													placement="top"
													title={
														value.status[0] === 'processing' &&
														setBadgeText(value, index)
													}
												>
													<a href={url} target="_blank" rel="noreferrer">
														<Badge
															offset={[-3, 2]}
															dot
															color={setBadgeColor(value, index)}
														>
															<Platforms platform={url} />
														</Badge>
													</a>
												</Tooltip>
											);
										})}
									</Flex>

									{sortedUrls?.length > 3 && (
										<Typography.Text>
											+ {sortedUrls?.length - 3} more
										</Typography.Text>
									)}
								</Flex>
							);
						}}
					/>

					<Table.Column
						title="Status"
						key="status"
						align="center"
						defaultSortOrder={getSortQueryParams(
							'status',
							searchParams.get('srt'),
						)}
						sorter={{ multiple: 2 }}
						render={(value: ReportStatusData, _, i) => (
							<div key={i}>
								{value.status[0] === 'processing' && (
									<Tag icon={<SyncOutlined spin />} color="processing">
										{value.status[0]}
									</Tag>
								)}

								{value.status[0] === 'generating' && (
									<Tag icon={<CheckCircleOutlined />} color="orange">
										{value.status[0]}
									</Tag>
								)}

								{value.status[0] === 'ready' && (
									<Tag icon={<CheckCircleOutlined />} color="green">
										{value.status[0]}
									</Tag>
								)}

								{(value.status[0] === 'timeout' ||
									value.status[0] === 'failed') && (
									<Tag icon={<CheckCircleOutlined />} color="red">
										{value.status[0]}
									</Tag>
								)}

								{value.status[0] === 'cancelled' && (
									<Tag icon={<CheckCircleOutlined />} color="volcano">
										{value.status[0]}
									</Tag>
								)}
							</div>
						)}
					/>

					<Table.Column
						title="Created"
						key="created_at"
						align="center"
						defaultSortOrder={getSortQueryParams(
							'created_at',
							searchParams.get('srt'),
						)}
						sorter={{ multiple: 3 }}
						render={(value: ReportStatusData, _, idx) => (
							<Tooltip
								key={idx}
								placement="top"
								title={dayjs(value.created_at[0]).format('HH:mm - D MMM YYYY')}
							>
								{dayjs(value.created_at[0]).fromNow()}
							</Tooltip>
						)}
					/>
					<Table.Column
						title="Period"
						key="period"
						align="center"
						render={(value: ReportStatusData) => {
							switch (value.scrape_to[0]) {
								case '31':
									return '1 month';
								case '92':
									return '3 months';
								case '153':
									return '6 months';
								case '366':
									return '1 year';
								case 'Since Start':
									return 'From account start';
								default:
									return (
										value.scrape_to[0] +
										`${value.scrape_to[0] === '1' ? ' day' : ' days'}`
									);
							}
						}}
					/>

					<Table.Column
						title="Report Tags"
						key="report_tags"
						align="center"
						filterDropdown={({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters = () => null,
						}) => (
							<div
								style={{
									padding: 8,
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								{/* <Input
									placeholder={`Search report tags`}
									value={selectedKeys[0]}
									onChange={(e) => {
										setSelectedKeys(e.target.value ? [e.target.value] : []);
									}}
									onPressEnter={() => confirm()}
									style={{ width: 188, marginBottom: 8, display: 'block' }}
									autoFocus={true}
								/> */}
								<Select
									mode="tags"
									// defaultValue={searchParams.get('tags')?.split(',') || []}
									autoFocus
									value={options}
									style={{ width: 250, marginBottom: 15, display: 'block' }}
									placeholder="Tags Mode"
									open={false}
									onChange={handleTagChange}
								/>
								<Space>
									<Button
										type="primary"
										onClick={() => {
											searchParams.set('tags', options.join(','));
											searchParams.set('p', '1');
											setSearchParams(searchParams);
											confirm({ closeDropdown: true });
										}}
										icon={<SearchOutlined />}
										size="small"
										style={{ width: 90 }}
									>
										Search
									</Button>
									<Button
										onClick={() => {
											searchParams.delete('tags');
											searchParams.set('p', '1');
											setSearchParams(searchParams);
											setOptions([]);
											confirm({ closeDropdown: true });
										}}
										size="small"
										style={{ width: 90 }}
									>
										Reset
									</Button>
								</Space>
							</div>
						)}
						filterIcon={(filtered) => (
							<SearchOutlined
								style={{
									color: searchParams.get('tags') ? '#1890ff' : undefined,
								}}
							/>
						)}
						onFilter={(value, record: any) =>
							record.tags?.includes(value.toString()) as boolean
						}
						render={(value: ReportStatusData, _, idx) => (
							<div
								key={idx}
								style={{
									display: 'flex',
									flexWrap: 'nowrap',
									justifyContent: 'space-between',
								}}
							>
								<div style={{ display: 'flex', flexWrap: 'wrap' }}>
									{value.tags &&
										value.tags[0].map((tag: string | null, id) => (
											<Tag
												key={tag || id}
												color={tag && tag?.toUpperCase() ? 'blue' : 'blue'} // To be decided
												style={{ margin: 3 }}
											>
												{tag}
											</Tag>
										))}
								</div>

								<div
									style={{
										display: 'flex',
										// alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Button
										style={{
											margin: '3px',
											maxWidth: '22px',
											maxHeight: '22px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
										disabled={value.status[0] === 'processing'}
										icon={<EditOutlined style={{ fontSize: '12px' }} />}
										onClick={() => {
											if (value.tags !== null) {
												setModalTags(value.tags[0]);
												setTagsReportId(value.report_id);
												showModal();
											} else {
												setModalTags([]);
												setTagsReportId(value.report_id);
												showModal();
											}
										}}
									>
										{/* {!value.tags && 'Add tag'} */}
									</Button>
								</div>
							</div>
						)}
					/>

					{profile.user.dView && showDebug && showDebug && (
						<Table.Column
							title="Debug"
							key="debug"
							align="left"
							width={285}
							render={(value: ReportStatusData) => {
								const errorsAmount = sum(value.errors),
									processedAmount = sum(value.processed);

								return (
									<div>
										<div
											style={{
												display: 'grid',
												gridTemplateColumns: '1fr 1fr',
											}}
										>
											<div>
												<b>Expect:</b> {sum(value.count)}
											</div>
											<div>
												<b>Processed:</b> {processedAmount}
											</div>
										</div>

										<div
											style={{
												display: 'grid',
												gridTemplateColumns: '1fr 1fr',
											}}
										>
											<div>
												<b>Errors:</b> {errorsAmount}
											</div>
											<div>
												<b>Total:</b> {processedAmount + errorsAmount}
											</div>
										</div>

										<div
											style={{
												display: 'grid',
												gridTemplateColumns: '1fr 1fr',
											}}
										>
											<div>
												<b>Started:</b>{' '}
											</div>
											<div>
												{dayjs(value.created_at[0]).format('HH:mm - DD.MM.YY')}
											</div>
										</div>

										{!value.finished_at[0] && (
											<div
												style={{
													display: 'grid',
													gridTemplateColumns: '1fr 1fr',
												}}
											>
												<div>
													<b>Expected:</b>{' '}
												</div>
												<div>
													{dayjs(value.created_at[0])
														.add(sum(value.count) * 100, 's')
														.format('HH:mm - DD.MM.YY')}
												</div>
											</div>
										)}

										{value.finished_at[0] && (
											<div
												style={{
													display: 'grid',
													gridTemplateColumns: '1fr 1fr',
												}}
											>
												<div>
													<b>Finished:</b>{' '}
												</div>
												<div>
													{dayjs(value.finished_at[0]).format(
														'HH:mm - DD.MM.YY',
													)}
												</div>
											</div>
										)}
									</div>
								);
							}}
						/>
					)}

					<Table.Column
						width={150}
						align="center"
						title="Actions"
						key="actions"
						ellipsis
						fixed="right"
						render={(value, _, idx) => {
							return (
								<Space
									key={idx}
									// block
									style={{ display: 'flex', justifyContent: 'center' }}
								>
									<Tooltip title="View Report">
										<Link
											to={`/app/dashboard/creators/${value.report_id}/report-v2`}
										>
											<Button
												disabled={value.status[0] !== 'ready'}
												icon={<BookOutlined />}
											/>
										</Link>
									</Tooltip>

									<Tooltip title="Stop Report">
										<Button
											disabled={!(value.status[0] === 'processing')}
											icon={<StopOutlined />}
											onClick={() => onStopReport(value.report_id)}
										/>
									</Tooltip>
									<Tooltip title="Run Again">
										<Button
											disabled={value.status[0] === 'processing'}
											icon={<RedoOutlined />}
											onClick={() => {
												const body = reGenReport(value);
												// searchParams.set('p', '1');
												// setSearchParams(searchParams);
												onGenerateReport(body);
											}}
										/>
									</Tooltip>
								</Space>
							);
						}}
					/>
				</Table>
			</Spin>

			<Drawer
				title="Influencer detail"
				width={'80%'}
				placement="right"
				closable={false}
				onClose={onClose}
				open={open}
				getContainer={false}
				extra={
					<Space>
						<Button onClick={onClose}>
							<CloseCircleOutlined /> Close
						</Button>
					</Space>
				}
			>
				<Creator id={creator} />
			</Drawer>

			<Modal
				destroyOnClose
				open={openModal}
				title="Edit tags"
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={handleOk}
					>
						Update
					</Button>,
				]}
			>
				<Form
					form={form}
					style={{ width: '100%' }}
					// onFinish={(v) => setSearch(v.search)}
				>
					<Form.Item htmlFor="tags" name="tags" style={{ marginBottom: 0 }}>
						<div className={style.form}>
							<Select
								mode="tags"
								open={false}
								showSearch={false}
								suffixIcon={null}
								tagRender={tagRender}
								style={{ width: '100%' }}
								placeholder="Report tags"
								options={modalTags.map((tag: any) => ({
									label: tag,
									value: tag,
								}))}
								disabled={loading}
								defaultValue={modalTags}
								onChange={(e) => {
									onTagChange(e);
								}}
								// onChange={setSelectedTags} // Update the selected tags whenever they change
							></Select>
						</div>
					</Form.Item>
				</Form>
			</Modal>

			<AddReport
				addReport={addReport}
				setAddReport={setAddReport}
				onFinish={() => reports.refetch()}
			/>

			{!isAdmin && (!repotsCount || !subscribe) && (
				<Modal
					destroyOnClose
					open={openModalCredits}
					centered
					title="Update subscription"
					// onOk={}
					onCancel={handleModalCredits}
					footer={[]}
					width={800}
				>
					<div style={{ display: 'flex', padding: '30px' }}>
						<div style={{ flex: '1 1 0' }}>
							<UpdateSubscription />
						</div>

						<div
							style={{
								flex: '1 1 0',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<p
								style={{
									fontSize: '1.7rem',
								}}
							>
								No credits remaining - please ask your system administrator to
								top up to continue !
							</p>
							<Button
								key="back"
								type="primary"
								onClick={handleModalCredits}
								style={{ width: 'fit-content', alignSelf: 'end' }}
							>
								Close
							</Button>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default Creators;

function sum(array: number[]) {
	return array.reduce((a, b) => a + b, 0);
}
