import { Space, Tag } from 'antd';
import { SortOrder } from 'antd/es/table/interface';

import ReportStatus from '@/components/ReportStatus';
import { INITIALIZATION_STATUSES_ENUM } from '@/const';
import prepareDate from '@/lib/helpers/prepareDate';

const STATUSES = Object.keys(INITIALIZATION_STATUSES_ENUM).map((key) => ({
	text: key.toString(),
	value: key,
}));

const mockData = [
	{
		batch_id: '9ed22bf8-9b80-4ae1-aa69-b55978e82abc',
		tags: ['news', 'transcribe', 'translate', 'hello'],
		actions: [
			'transcribe',
			'translate',
			'music-detect',
			'audio-quality',
			'ner',
			'toxicity',
			'iab-categories',
			'personality',
			'brand-safety',
			'leaning',
			'tonality',
			'keywords',
			'direction-focus',
		],
		date_from_process: '2024-07-01T00:00:00Z',
		days_from_process: '42',
		status: 'ready',
		progress: 97.612312312,
		started: '2024-08-12T01:07:52.023198Z',
		finished: '2024-08-12T13:29:00.448179Z',
		type: 'batch',
	},
	{
		batch_id: 'a9b0b4eb-5423-4897-afff-89d4425bdc10',
		tags: ['news'],
		actions: [
			'transcribe',
			'translate',
			'music-detect',
			'audio-quality',
			'ner',
			'toxicity',
			'iab-categories',
			'personality',
			'brand-safety',
			'leaning',
			'tonality',
			'keywords',
			'direction-focus',
		],
		date_from_process: '2024-08-05T00:00:00Z',
		days_from_process: '7',
		status: 'timeout',
		progress: 18.75,
		started: '2024-08-12T00:41:52.933329Z',
		finished: '2024-08-12T01:57:00.825589Z',
		type: 'batch',
	},
];

const columns = [
	{
		title: 'BatchId',
		dataIndex: 'batch_id',
		key: 'batch_id',
		sorter: false,
		width: 150,
		flex: true,
		ellipsis: true,
		render: (_: any, record: any) => {
			return (
				<p title={record?.batch_id}>{record?.batch_id?.split('-')?.at(-1)}</p>
			);
		},
	},
	{
		title: 'Date/time requested',
		dataIndex: 'created_at',
		key: 'created_at',
		// sorter: true,
		sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
		width: 200,
		flex: true,
		// hidden: true,
		render: (_: any, record: any) => {
			return <>{prepareDate(record?.started!)}</>;
		},
	},
	{
		title: 'Tags',
		dataIndex: 'tags',
		key: 'tags',
		// sorter: false,
		width: 160,
		flex: true,
		// hidden: true,
		render: (_: any, record: any) => {
			const slicedTags =
				record?.tags?.length > 3 ? record?.tags.slice(0, 3) : record?.tags;
			return (
				<Space size={[1, 1]}>
					{slicedTags?.map((item: string, idx: number) => (
						<Tag key={item + idx}>{item}</Tag>
					))}
					{record?.tags?.length > 3 && (
						<small
							style={{
								marginLeft: '0.25rem',
								display: 'block',
								width: 'max-content',
							}}
						>
							+ {record?.tags?.length - 3} more
						</small>
					)}
				</Space>
			);
		},
	},
	// {
	// 	title: 'URLs count',
	// 	dataIndex: 'urls',
	// 	key: 'urls',
	// 	// sorter: false,
	// 	width: 50,
	// 	flex: true,
	// 	// hidden: true,
	// 	render: (_: any, record: any) => {
	// 		return record?.urls?.length;
	// 	},
	// },
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		// sorter: false,
		width: 110,
		flex: true,
		// filters: STATUSES,
		filterMultiple: false,
		// hidden: true,
		render: (_: any, record: any) => {
			return <ReportStatus status={record?.status} />;
		},
	},
	{
		title: 'Progress',
		dataIndex: 'progress',
		key: 'progress',
		sorter: false,
		// sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
		width: 100,
		flex: true,
		// hidden: true,
		render: (_: any, record: any) => {
			return (
				<>
					{typeof record?.progress === 'number'
						? `${Math.round(record?.progress * 100) / 100}%`
						: '-'}
				</>
			);
		},
	},
	// {
	// 	title: 'Actions',
	// 	dataIndex: 'actions',
	// 	key: 'actions',
	// 	// sorter: false,
	// 	width: 50,
	// 	flex: true,
	// 	// hidden: true,
	// 	render: (_: any, record: any) => {
	// 		return (
	// 			<Tooltip title="View details">
	// 				<Button
	// 					// type='primary'
	// 					disabled={record.status !== 'ready'}
	// 					icon={<BookOutlined />}
	// 				/>
	// 			</Tooltip>
	// 		);
	// 	},
	// },
];

export { columns, mockData };
