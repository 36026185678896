import { useContext, useEffect } from 'react';

import { Button, Drawer, Flex, List, Space, Typography } from 'antd';
import Link from 'antd/es/typography/Link';

import { SubscriptionContext } from '@/App';
import { useGetProductSubscription } from '@/api/customer_subscription';
import { productItems } from '@/layouts/subscription/NewProductSubscription/data';
import { PageContext } from '@/providers/PageContextProvider';

export default function ViewProductSubscriptionDrawer() {
	const { drawer, closeDrawer } = useContext(PageContext);
	const { subscriptions } = useContext(SubscriptionContext);

	const { data, isError, error, isLoading } = useGetProductSubscription(
		drawer?.data?.subscriptionName,
	);

	const onClose = () => {
		closeDrawer();
	};

	const productItem = productItems.find((item) => item.id === drawer?.data?.id);

	// useEffect(() => {
	// 	if (isError) {
	// 		const getError = error as any;

	// 		message.error({
	// 			type: 'error',
	// 			content: `Something went wrong: ${
	// 				getError?.response?.data?.message ?? getError?.message
	// 			}`,
	// 		});
	// 	}
	// }, [isError]);

	// console.log('batch_id', drawer?.data?.batch_id);
	// console.log('useGetBatchProcessesById', data?.data?.data);

	const isAlreadyInSubscriptions =
		drawer.type === 'view-product-subscription' &&
		subscriptions?.some((current) =>
			current?.subscription_name
				?.toLowerCase()
				?.includes(drawer?.data?.subscriptionName!.toLowerCase()),
		);

	return (
		<Drawer
			open={drawer.isOpen && drawer.type === 'view-product-subscription'}
			width={'650px'}
			onClose={onClose}
			closable={false}
			maskClosable={false}
			loading={isLoading}
			title={
				<Flex gap={16} align="center">
					<img
						src={productItem?.image}
						alt="Product image"
						width={120}
						height={'auto'}
						style={{ borderRadius: '10px', overflow: 'hidden' }}
					/>
					<Typography.Title level={2}>
						{productItem?.subscriptionName}
					</Typography.Title>
				</Flex>
			}
		>
			<Flex justify="flex-end">
				<Space>
					<Button onClick={onClose}>Close</Button>
					<Link href="https://www.socialvoice.ai" target="_blank">
						<Button type="primary" disabled={isAlreadyInSubscriptions}>
							{isAlreadyInSubscriptions ? 'Already subscribed' : 'Buy now'}
						</Button>
					</Link>
				</Space>
			</Flex>

			<Flex vertical gap={10} align="flex-start" justify="flex-start">
				<Typography.Title level={5} style={{ marginBottom: 0 }}>
					Description
				</Typography.Title>
				<Typography.Text>
					{data?.data?.[0]?.description ??
						`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					minim veniam, quis nostrud exercitation ullamco laboris nisi ut
					aliquip ex ea commodo consequat. Duis aute irure dolor in
					reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
					pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
					culpa qui officia deserunt mollit anim id est laborum.`}
				</Typography.Text>
				<Typography.Title level={5} style={{ marginBottom: 0 }}>
					Features
				</Typography.Title>
				<List size="small">
					<List.Item>Lorem ipsum dolor sit amet</List.Item>
					<List.Item>consectetur adipiscing elit,</List.Item>
					<List.Item>sed do eiusmod tempor </List.Item>
					<List.Item>Duis aute irure dolor</List.Item>
					<List.Item> Excepteur sint occaecat</List.Item>
				</List>
				{/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
				tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
				veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
				commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
				velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
				occaecat cupidatat non proident, sunt in culpa qui officia deserunt
				mollit anim id est laborum. */}
				{/* </Typography.Text> */}
			</Flex>
		</Drawer>
	);
}
