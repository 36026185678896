import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Skeleton, Space } from 'antd';
import { log } from 'console';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserContext } from '@/App';
import SubscriptionCartFormItem from '@/layouts/subscription/SubscriptionCartFormItem';
import client from '@/lib/http/client';

import './style-subscription.css';

import CheckoutForm from './form';
import { FormSkeleton } from './formSkeleton';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const Subscribe = () => {
	const [clientSecret, setClientSecret] = React.useState('');
	const location = useLocation();
	const subscription = location.state?.subscription;
	const navigate = useNavigate();
	React.useEffect(() => {
		// if (!subscription) {
		// 	navigate('/app/dashboard/subscription-plans');
		// 	return;
		// }

		client
			.post('/payment/pi-subscription', {
				subscription_id: subscription.id,
			})
			.then((res) => res.data)
			.then((data) => setClientSecret(data.clientSecret));
	}, []);

	const options = {
		clientSecret,
	};

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '89vh',
				width: '100%',
			}}
			className="subscription-wrapper"
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
				}}
			>
				{subscription && (
					<SubscriptionCartFormItem
						subscription={subscription}
						index={subscription.id}
						subscribe={false}
						subscriptionId={subscription.id}
					/>
				)}
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
				}}
			>
				{clientSecret ? (
					<Elements options={options} stripe={stripePromise}>
						<CheckoutForm />
					</Elements>
				) : (
					<FormSkeleton />
				)}
			</div>
		</div>
	);
};

export default Subscribe;
